@import "../../assets/sass/includes";

.header-navbar {
  padding: 10px 15px !important;

  .navbar-brand {
    display: inline-block;
    padding: 0 !important;
    margin-right: 1rem;
    font-size: 1.25rem;
    white-space: nowrap;

    h2 {
      font-size: 26px;
      font-weight: 700;
      line-height: 59px;
      color: transparent;
      height: 59px;
      padding: 0 !important;
      margin: 0 !important;
      background: url(../../assets/images/logo/logo-text.png) center no-repeat;
      background-size: 100%;
    }
  }

  .nav-item {
    font-size: medium;

    .nav-link {
      font-weight: 500;
      font-size: 14px;
      color: #111;
      padding-left: 18px !important;
      padding-right: 18px !important;
      border-bottom: 2px solid transparent;

      &.active-page {
        color: $color-purple;
        border-color: $color-purple;
      }

      @media only screen and (max-width: 767px) {
        border-bottom: none !important;
        border-left: 4px solid transparent;
      }
    }
  }
}
