@import "../../assets/sass/includes";

$circular-button-padding-horizontal: 20px;
$circular-button-padding-vertical: 6px;

.circular-button {
  position: relative;
  border: 0;
  padding: $circular-button-padding-vertical $circular-button-padding-horizontal;
  border: 1px solid $color-purple;
  color: $color-purple;
  background: #fff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
  @include animate(border-radius 0.3s);
  @include no-select;

  &.circular-button-primary {
    background: $color-purple;
    color: #fff;
  }

  &:hover {
    border-radius: 50px;
  }
}
