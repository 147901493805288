@import "../../../assets/sass/includes";

.services-area {
  background: url(../../../assets/images/site/home-page/bg-services.png)
    no-repeat center;
  background-size: 100%;
  text-align: center;
  padding: 50px 0;
  min-height: 600px;
  margin-bottom: 50px;
}
