@import "../../assets/sass/includes";

.error-404 {
  .top-area,
  .body-area,
  .bottom-area {
    text-align: center;
  }

  .top-area {
    background: url(../../assets/images/site/not-found/bg-header-pages.png) no-repeat;
    background-size: 100% 100%;
    padding: 75px 0;
  }

  .body-area {
    text-align: center;
    padding-bottom: 130px;
  }

  .bottom-area {
    padding-bottom: 130px;
  }
}
