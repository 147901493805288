@import "../../../assets/sass/includes";

.home-pricing {
  background: url(../../../assets/images/site/home-page/bg-pricing.png)
    no-repeat center;
  background-size: 100%;
  min-height: 400px;
  text-align: center;
  padding: 25px 0;
  margin-bottom: 80px;
}
