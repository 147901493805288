@import "../../../assets/sass/includes";

$contact-info-card-icon-size: 20px;

.contact-info-card {
  position: relative;
  display: flex;
  align-items: center;
  padding: 30px 15px 10px 15px;
  margin-bottom: 25px;

  &, .card-icon {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 5px;

    @include animate(
      -webkit-box-shadow 0.3s,
      -moz-box-shadow 0.3s,
      box-shadow 0.3s
    );
  }

  &:hover,
  &:hover .card-icon {
    @include box-shadow(0 1px 5px 0 rgb(220, 220, 220));
  }

  .card-icon {
    position: absolute;
    top: -#{$contact-info-card-icon-size / 2 + 2px};
    left: #{$contact-info-card-icon-size / 2 + 2px};
    display: flex;
    align-items: center;
    padding: 2px 12px;

    .icon {
      height: $contact-info-card-icon-size;
      width: $contact-info-card-icon-size;
      margin-right: 5px;
      @include flex-center;

      i {
        font-size: #{$contact-info-card-icon-size * 0.7};
      }
    }

    .type {
      font-size: 12px;
      font-weight: bold;
      opacity: 0.8;
      text-transform: uppercase;
      @include no-select;
    }
  }

  .card-text {
    min-height: $contact-info-card-icon-size;
    font-size: 13px;
  }
}
