
@import "../fonts/fontawesome/scss/fontawesome.scss";
@import "../fonts/fontawesome/scss/brands.scss";
@import "../fonts/fontawesome/scss/regular.scss";
@import "../fonts/fontawesome/scss/solid.scss";

@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700,800,900");
@import "~bootstrap/scss/bootstrap.scss";
@import "./includes/variables";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  background: #fff;
  color: #787878;
  font-size: 14px;
  line-height: 22px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #111;
}

button,
select,
input,
textarea {
  outline: none !important;
}

a,
a:hover,
a:visited {
  color: $color-purple;
  font-weight: bold;
}

*::-webkit-scrollbar {
  height: $scrollbar-size;
  width: $scrollbar-size;
}

*::-webkit-scrollbar-thumb {
  background: #777;
  border-radius: $scrollbar-size;
  min-height: 15px;
}

* {
  scrollbar-width: thin;
}

::-moz-selection {
  background: $color-purple-light;
  color: #fff;
}

::selection {
  background: $color-purple-light;
  color: #fff;
}
