@import "../../../assets/sass/includes";

$pricing-item-icon-size: 75px;
$pricing-item-icon-size-large: 85px;
$pricing-item-icon-space: 0.35;


.pricing-item {
  margin-top: #{$pricing-item-icon-size * (1 - $pricing-item-icon-space)};
  padding: 25px 20px;
  border-radius: 7px;
  min-height: 300px;
  position: relative;
  background: #fff;

  &,
  .card-icon {
    @include box-shadow(0 2px 9px 0 rgba(200, 200, 200, 0.35));
    @include animate(
      -webkit-box-shadow 0.3s,
      -moz-box-shadow 0.3s,
      box-shadow 0.3s,
      -webkit-transform .3s,
      -moz-transform .3s,
      -ms-transform .3s,
      -o-transform .3s,
      transform .3s,
    );
  }

  &:hover {
    @include transform(scale(1.03));
  }

  &:hover,
  &:hover .card-icon {
    @include box-shadow(0 2px 9px 0 rgb(200, 200, 200) !important);
  }
  
  &:hover .card-icon i,
  &:hover .card-price {
    color: $color-purple;
  }

  .card-icon {
    position: absolute;
    height: $pricing-item-icon-size;
    width: $pricing-item-icon-size;
    top: -#{$pricing-item-icon-size * $pricing-item-icon-space};
    right: -#{$pricing-item-icon-size * $pricing-item-icon-space};
    border-radius: 50%;
    background: #fff;
    @include flex-center;

    i {
      font-size: #{$pricing-item-icon-size * 0.45};
      @include animate(color 0.25s);
    }
  }

  .card-plan {
    padding: 10px 0 20px 0;
    font-size: 18px;
    font-weight: 500;
  }

  .card-price {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 30px;
    @include animate(color 0.25s);
  }

  .card-supports ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      padding: 5px 0;
      cursor: default;
    }
  }

  @media only screen and (max-width: 634px) {
    margin-top: #{$pricing-item-icon-size-large * (1 - $pricing-item-icon-space)};
    padding-top: #{$pricing-item-icon-size-large * (1 - $pricing-item-icon-space)};
    
    .card-icon {
      left: calc(50% - #{$pricing-item-icon-size-large / 2});
      right: calc(50% - #{$pricing-item-icon-size-large / 2});
      top: -#{$pricing-item-icon-size-large / 2};
      height: $pricing-item-icon-size-large;
      width: $pricing-item-icon-size-large;

      i {
        font-size: #{$pricing-item-icon-size-large * 0.45};
      }
    }
  }
}
