@import "../../../assets/sass/includes";

.header-intro {
  background: url(../../../assets/images/site/layout/bg-hero.png) no-repeat;
  background-size: contain;

  .entry {
    padding: 75px 0;
  }

  img.hero-image {
    width: 100%;
  }

  h2.big-title {
    margin: 20px 0;
  }
}
