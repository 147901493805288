@import "../../assets/sass/includes";

.pricing {
  background: url(../../assets/images/site/pricing/bg-header-pages.png) no-repeat;
  background-size: contain;
  text-align: center;
  padding-top: 60px;

  .body-area {
    margin-bottom: 80px;
  }
}
