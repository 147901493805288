@import "../../assets/sass/includes";

.reporting {
  background: url(../../assets/images/site/pricing/bg-header-pages.png)
    no-repeat;
  background-size: contain;
  text-align: center;
  padding-top: 60px;

  .body-area {
    max-width: 500px;
    margin: 0 auto;
    padding: 30px 0;
    text-align: left;

    .year-row {
      padding: 15px;
      border: 2px dashed rgba($color-purple-middle, 0.5);
      border-radius: 7px;
      margin-bottom: 15px;
      display: flex;
    }

    .year-row-title {
        font-weight: bold;
        font-size: 28px;
        width: 100px;
    }

    .year-row-quarters {
        .quarter-row {
            display: block;
            font-size: 18px;
        }
    }
  }
}
