@import "../../../../assets/sass/includes";

$service-item-icon-size: 85px;

.service-item {
  min-height: 260px;
  padding: 25px;
  border-radius: 7px;
  margin: 0 15px 15px 15px;
  flex-direction: column;
  @include flex-center;
  @include animate(
    background 0.3s,
    -webkit-box-shadow 0.3s,
    -moz-box-shadow 0.3s,
    box-shadow 0.3s
  );

  &:hover {
    background: #fff;
    @include box-shadow(0 2px 9px 0 rgb(200, 200, 200));
  }

  .service-item-icon {
    width: $service-item-icon-size;
    height: $service-item-icon-size;
    margin-bottom: 15px;

    i {
      font-size: #{$service-item-icon-size * 0.75};
      color: $color-purple-middle;
    }
  }

  .service-item-title {
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 20px;
    color: #000;
    @include no-select;
  }

  .service-item-text {
    font-size: 14px;
    font-weight: 400;
  }
}
