@import "../../assets/sass/includes";

$google-map-height: 550px;
$marker-pin-size: 30px;
$marker-pin-shadow-size: 14px;
$marker-pin-point-size: 16px;

$marker-pin-margin-top: -#{$marker-pin-size * 0.7};
$marker-pin-margin-left: -#{$marker-pin-size / 2};
$marker-pin-point-margin-top: #{($marker-pin-size - $marker-pin-point-size) / 2};
$marker-pin-point-margin-left: #{($marker-pin-size - $marker-pin-point-size) / 2};

.contact-us {
  background: url(../../assets/images/site/contact-us/bg-header-pages.png) no-repeat;
  background-size: contain;

  @include key-frame(pulsate) {
    0% {
      @include transform(scale(0.1, 0.1));
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      @include transform(scale(1.2, 1.2));
      opacity: 0;
    }
  }

  @include key-frame(bounce) {
    0% {
      @include transform(translateY(-2000px) rotate(-45deg));
      opacity: 0;
    }
    60% {
      @include transform(translateY(30px) rotate(-45deg));
      opacity: 1;
    }
    80% {
      @include transform(translateY(-10px) rotate(-45deg));
    }
    100% {
      @include transform(translateY(0) rotate(-45deg));
    }
  }

  @include key-frame(topleftshake) {
    0%, 24%, 48% {
      margin-top: #{$marker-pin-margin-top};
    }
    12%, 36% {
      margin-top: calc(#{$marker-pin-margin-top} - 20px);
    }
  }

  padding-top: 85px;

  .contact-info {
    padding-bottom: 40px;
  }

  .contact-map {
    padding-bottom: 130px;

    .contact-map-container {
      height: $google-map-height;
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      @include box-shadow(0 1px 10px 0 rgb(180, 180, 180));
    }

    .map-marker {
      margin-top: -#{$marker-pin-size / 2};
      position: relative;

      .pin {
        width: $marker-pin-size;
        height: $marker-pin-size;
        border-radius: 50% 50% 50% 0;
        background: $color-purple;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: $marker-pin-margin-top 0 0 $marker-pin-margin-left;
        @include transform(rotate(-45deg));
        @include key-frame-animate(bounce both 1s, topleftshake infinite 2.5s);
        @include animate(margin 0.2s);

        &::after {
          content: "";
          width: $marker-pin-point-size;
          height: $marker-pin-point-size;
          margin: $marker-pin-point-margin-top 0 0 $marker-pin-point-margin-left;
          background: $color-purple-dark;
          position: absolute;
          border-radius: 50%;
        }
      }

      .pulse {
        background: rgba(#000, 0.3);
        border-radius: 50%;
        height: $marker-pin-shadow-size;
        width: $marker-pin-shadow-size;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: $marker-pin-shadow-size 0 0 -#{$marker-pin-shadow-size / 2};
        transform: rotateX(55deg);
        z-index: -2;

        &::after {
          content: "";
          border-radius: 50%;
          height: 40px;
          width: 40px;
          position: absolute;
          margin: -13px 0 0 -13px;
          animation: pulsate 1s ease-out;
          animation-iteration-count: infinite;
          opacity: 0;
          box-shadow: 0 0 1px 2px #89849b;
          animation-delay: 1.1s;
        }
      }
    }
  }
}
