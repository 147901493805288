@import "../../../assets/sass/includes";

.about-area {
  background: url(../../../assets/images/site/home-page/bg-about-us.png) no-repeat;
  background-size: 100%;

  .entry {
    padding: 75px 0;
  }

  img.about-image {
    width: 100%;
  }

  h2.big-title {
    margin: 20px 0;
  }
}
