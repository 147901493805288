@mixin no-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin transform($values) {
  -webkit-transform: $values;
  -moz-transform: $values;
  -ms-transform: $values;
  -o-transform: $values;
  transform: $values;
}

@mixin box-shadow($values...) {
  -webkit-box-shadow: $values;
  -moz-box-shadow: $values;
  -ms-box-shadow: $values;
  -o-box-shadow: $values;
  box-shadow: $values;
}

@mixin box-sizing($value) {
  -webkit-box-sizing: $value;
  -moz-box-sizing: $value;
  box-sizing: $value;
}

@mixin animate($values...) {
  -webkit-transition: $values;
  -moz-transition: $values;
  -ms-transition: $values;
  -o-transition: $values;
  transition: $values;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin key-frame-animate($values...) {
  -webkit-animation: $values;
  -moz-animation: $values;
  -ms-animation: $values;
  -o-animation: $values;
  animation: $values;
}

@mixin key-frame($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
