@import "../../assets/sass/includes";

.purple-tite {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: $color-purple;
  letter-spacing: 0.4px;
  @include no-select;
}
