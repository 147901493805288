@import "../../../assets/sass/includes";

.join-us-area {
  padding: 55px 0;

  .container-with-bg {
    background: #f9f9f9 url(../../../assets/images/site/home-page/bg-join-us.png) top
      center no-repeat;
    background-size: cover;
    padding: 100px 20px;
    border-radius: 10px;
    font-size: 34px;
    font-weight: bold;
    color: $color-purple-dark;
    opacity: 0.6;
    text-align: center;
    @include flex-center;
    @include no-select;
    @include animate(opacity 0.2s);

    &:hover {
      opacity: 1;
    }

    @media only screen and (max-width: 767px) {
      line-height: 38px;
    }

    @media only screen and (max-width: 575px) {
      border-radius: 0;
    }
  }
}
