@import "../../assets/sass/includes";

$footer-icon-size: 100px;

.footer-page {
  text-align: center;
  background: $color-purple-dark;
  padding: 40px 29%;
  color: #ddd;
  line-height: 22px;
  font-weight: 400;

  h2,
  h6 {
    color: #fff;
  }

  h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  h6 {
    font-size: 13px;
    @include no-select;
  }

  .footer-icon {
    height: $footer-icon-size;
    width: $footer-icon-size;
    margin: 20px auto 50px auto;
    background: url(../../assets/images/logo/logo-icon.png) center no-repeat;
    background-size: 100%;
  }
}
